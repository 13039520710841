<template>
  <section class="account-bind-wrap">
    <section class="account-bind-header">
      <h3 class="title"><i></i>账号绑定</h3>
    </section>

    <section class="account-bind-main"></section>
  </section>
</template>

<script>
export default {
  name: 'accountBind'
};
</script>

<style lang="scss" scoped>
.account-bind-wrap {
  .account-bind-header {
    position: relative;
    padding: 15px;
    font-size: 0;
    border-bottom: 1px solid #f4f4f4;

    .title {
      height: 22px;
      font-size: 16px;
      line-height: 22px;

      i {
        display: inline-block;
        margin-right: 5px;
        width: 22px;
        height: 22px;
        background: url('../../assets/image/icon1.png') -2px -97px no-repeat;
        vertical-align: top;
      }
    }
  }

  .account-bind-main {
  }
}
</style>
